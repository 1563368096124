import React from 'react';
import { Link } from 'react-router-dom';

import FooterLogo from './Img/site-logo.png';
import FaceBookIcon from './Img/facebook-icon.svg';
import InstagramIcon from './Img/instagram-icon.svg';
import LinkedinIcon from './Img/linkedin-icon.svg';
import AngleIcon from './Img/angle-icon.svg';

import ISOLOGO from './Img/iso-logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className='site-footer'>
      <div className='main-container'>
        <div className='footer-content'>
          <div className='Ff_Foot_Part'>
            <Link to='/' className='foot_Logo'>
              <img src={FooterLogo} alt='footer logo' />
            </Link>
            <div className='Ff_Foot_Part_Cards'>
              <div className='Ff_Foot_Part_Card'>
                <h3>Nigeria</h3>
                <ul>
                  <li>
                    <h4>Address</h4>
                    <p>
                      Plot 5 Owule Ojuan Street, off Peter Odili Road,
                      <br />
                      Trans Amadi, Port Harcourt, Rivers
                    </p>
                  </li>
                  <li>
                    <h4>Phone</h4>
                    <p>+234 807 970 1019</p>
                    <p>+234 911 459 7013</p>
                  </li>
                  <li>
                    <h4>Email</h4>
                    <p>info@prolianceltd.com</p>
                  </li>
                 
                </ul>
              </div>
              <div className='Ff_Foot_Part_Card'>
                <h3>United Kingdom</h3>
                <ul>
                  <li>
                    <h4>Address</h4>
                    <p>
                      Unit 1 Church Farm Court, Capenhurst Ln,
                      <br />
                      Capenhurst, Chester CH1 6HE
                    </p>
                  </li>
                  <li>
                    <h4>Phone</h4>
                    <p>0330 124 6524</p>
                  </li>
                  <li>
                    <h4>Email</h4>
                    <p>info@prolianceltd.com</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='Ss_Foot_Part'>
            <div className='Nav_Social_icons'>
              <p>Connect with us</p>
              <span>
                <img src={AngleIcon} alt='Angle Icon' />
              </span>
              <div className='social_Icons_main'>
              <a href="https://www.linkedin.com/company/proliance-limited/about/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M7.734 27V9.48H2.285V27h5.45zM4.98 7.137c1.758 0 3.165-1.465 3.165-3.223C8.145 2.214 6.738.81 4.98.81a3.126 3.126 0 00-3.105 3.105c0 1.758 1.406 3.223 3.105 3.223zM28.066 27h.059v-9.61c0-4.687-1.055-8.32-6.563-8.32-2.636 0-4.394 1.465-5.156 2.813h-.058V9.48h-5.215V27h5.449v-8.672c0-2.285.41-4.453 3.223-4.453 2.812 0 2.87 2.578 2.87 4.629V27h5.391z" fill="currentColor"></path></svg>
            </a>
            <a href="https://twitter.com/prolianceltd" target='_blank'>
              <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M4.054 4l8.493 12.136L4 26h1.924l7.483-8.637L19.455 26H26l-8.972-12.817L24.984 4H23.06l-6.891 7.953L10.599 4H4.055zm2.829 1.514H9.89l13.28 18.971h-3.007L6.883 5.515z" fill="currentColor"></path></svg>
            </a>
            <a href="https://www.facebook.com/prolianceltd/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M21.973 17.625l.82-5.39h-5.215V8.718c0-1.524.703-2.93 3.047-2.93h2.402V1.16S20.86.75 18.81.75c-4.278 0-7.09 2.637-7.09 7.324v4.16H6.914v5.391h4.805V30.75h5.86V17.625h4.394z" fill="currentColor"></path></svg>
            </a>
              </div>
            </div>
            <ul className='Ss_Foot_Part_Ull'>
            <li>
                <Link to='/consultancy'>Consultancy</Link>
              </li>
              <li>
                <Link to='/engineering'>Engineering</Link>
              </li>
              <li>
                <Link to='/project-management'>Project Management</Link>
              </li>
              <li>
                <Link to='/supply-chain-management'>Supply Chain Management</Link>
              </li>
              <li>
                <Link to='/learning-and-development'>Learning and Development</Link>
              </li>
              <li>
                <Link to='/about'>About Us</Link>
              </li>

              <li>
                <Link to='/industries'>Industries</Link>
              </li>

              <li>
                <Link to='/careers'>Careers</Link>
              </li>
              <li>
                <Link to='/contact-us'>Contact us</Link>
              </li>
              <li>
                <Link to='/privacy-policy'>Data and Privacy Policy</Link>
              </li>
            </ul>
            {/* <img src={ISOLOGO} alt='iso logo' className='iso-logo'></img> */}
          </div>
        </div>

        <div className='MM_SUb_Footer'>
          <p>© {currentYear} Proliance LTD. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
