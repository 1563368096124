import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DropIcon from './Img/drop-icon.svg';
import AngleRightIcon from './Img/angle-right-icon.svg';



// Importing images
import Services1 from './Img/SliderImages/1.jpg';
import Services2 from './Img/SliderImages/4.jpg';
import Services3 from './Img/SliderImages/2.jpg';
import Services4 from './Img/SliderImages/3.jpg';
import Services5 from './Img/Services/5.jpg';


import SiteLogo1 from './Img/site-logo1.png';

const TopNavbar = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [scrollNavActive, setScrollNavActive] = useState(false); // State for tracking scroll position
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setDropdownActive((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownActive(false);
    }
  };

  const handleCardClick = () => {
    setDropdownActive(false);
  };

  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownActive]);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 80) {
        setScrollNavActive(true);
      } else {
        setScrollNavActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`Top_NavBar ${dropdownActive ? 'Active_DropDown' : ''} ${scrollNavActive ? 'Active_Scroll_Nav' : ''}`} ref={dropdownRef}>
      <div className='site-container'>
        <div className='Nav_Content'>
          <div className='Nav_Ll_Dtxt' onClick={handleCardClick}>
          <Link to="/" className='site-logo' >
              <img src={SiteLogo1} alt='Site Logo' />
            </Link>
            <p>
              <span>NIGERIA:</span> 
              <a href='tel:+2348079701019' aria-label='Call us at +234 807 970 1019'>+234 807 970 1019</a>
            </p>
            <p>
              <span>UK:</span> 
              <a href='tel:03301246524' aria-label='Call us at 0330 124 6524'>0330 124 6524</a>
            </p>
          </div>
 
        </div>
      </div>

      {dropdownActive && (
        <div className='OODrop_Down'>
          <div className='site-container'>
            <h3 className='big-text'>Our Services</h3>
            <div className='OODrop_Down_Content'>
              <Link to="/consultancy" className='OODrop_Down_Card' onClick={handleCardClick}>
                <img src={Services1} alt='Services banner' className='Serv_Drop_Banner'></img>
                <p>Consultancy</p>
                <span className='a-button a-button-1'>
                  Read More <img src={AngleRightIcon} alt='Angle right icon'></img>
                </span>
              </Link>

              <Link to="/engineering" className='OODrop_Down_Card' onClick={handleCardClick}>
                <img src={Services2} alt='Services banner' className='Serv_Drop_Banner'></img>
                <p>Engineering</p>
                <span className='a-button a-button-1'>
                  Read More <img src={AngleRightIcon} alt='Angle right icon'></img>
                </span>
              </Link>

              <Link to="/project-management" className='OODrop_Down_Card' onClick={handleCardClick}>
                <img src={Services3} alt='Services banner' className='Serv_Drop_Banner'></img>
                <p>Project Management</p>
                <span className='a-button a-button-1'>
                  Read More <img src={AngleRightIcon} alt='Angle right icon'></img>
                </span>
              </Link>

              <Link to="/supply-chain-management" className='OODrop_Down_Card' onClick={handleCardClick}>
                <img src={Services4} alt='Services banner' className='Serv_Drop_Banner'></img>
                <p>Supply Chain Management</p>
                <span className='a-button a-button-1'>
                  Read More <img src={AngleRightIcon} alt='Angle right icon'></img>
                </span>
              </Link>

              <Link to="/learning-and-development" className='OODrop_Down_Card' onClick={handleCardClick}>
                <img src={Services5} alt='Services banner' className='Serv_Drop_Banner'></img>
                <p>Learning and Development</p>
                <span className='a-button a-button-1'>
                  Read More <img src={AngleRightIcon} alt='Angle right icon'></img>
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default TopNavbar;
