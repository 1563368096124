import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SiteLogo from './Img/site-logo.png';
import SearchIcon from './Img/search-icon.svg';
import AngleIcon from './Img/angle-icon.svg';
import FaceBookIcon from './Img/facebook-icon.svg';
import InstagramIcon from './Img/instagram-icon.svg';
import LinkedinIcon from './Img/linkedin-icon.svg';
import ChatIcon from './Img/chat-icon.svg';
import WhatSappIcon from './Img/whatsapp-icon.svg';
import CallIcon from './Img/callIcon.svg';

import AngleRightIcon from './Img/angle-right-icon.svg';


import DropIcon from './Img/drop-icon.svg';


// Importing images
import Services1 from './Img/SliderImages/1.jpg';
import Services2 from './Img/SliderImages/4.jpg';
import Services3 from './Img/SliderImages/2.jpg';
import Services4 from './Img/SliderImages/3.jpg';
import Services5 from './Img/Services/5.jpg';


import SiteLogo1 from './Img/site-logo1.png';


const Navbar = () => {

  const [isNavActive, setIsNavActive] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isLetTalkVisible, setIsLetTalkVisible] = useState(false); // State for Let_talk_sec visibility


  const sectionRef = useRef(null); 

  const handleNavToggle = () => {
    setIsNavActive(!isNavActive);
  };

  const handleNavBodyClick = () => {
    setIsNavActive(false);
  };

  const handleLinkClick = () => {
    setIsNavActive(false);
  };

  const handleSearchToggle = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleCloseSearch = () => {
    setIsSearchVisible(false);
  };

  const handleClickOutside = (event) => {
    if (sectionRef.current && !sectionRef.current.contains(event.target)) {
      if (isLetTalkVisible) {
        setIsLetTalkVisible(false); // Hide Let_talk_sec
      }
    }
  };

  const toggleLetTalkVisibility = () => {
    setIsLetTalkVisible(!isLetTalkVisible); // Toggle Let_talk_sec visibility
  };

  const handleCloseButtonClick = () => {
    setIsLetTalkVisible(false); // Hide Let_talk_sec
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLetTalkVisible]);
  

  const [dropdownActive, setDropdownActive] = useState(false);
  const [scrollNavActive, setScrollNavActive] = useState(false); // State for tracking scroll position
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setDropdownActive((prevState) => !prevState);
  };



  const handleCardClick = () => {
    setDropdownActive(false);
  };

  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownActive]);

  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownActive]);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 80) {
        setScrollNavActive(true);
      } else {
        setScrollNavActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
<nav 
  className={`Main_navBar 
    ${isNavActive ? 'Active_Mobile_Nav' : ''} 
    ${isSearchVisible ? 'Show_Search_DropDown' : ''} 
    ${dropdownActive ? 'Active_DropDown' : ''} 
    ${scrollNavActive ? 'Active_Scroll_Nav' : ''}`} 
  ref={dropdownRef}
>
      <div className='site-container'>
        <div className='First_Mm_Nav'>
          <div className='Left_L_Main_Nav'>
            <Link to="/" className='site-logo'>
              <img src={SiteLogo} alt='Site Logo' />
            </Link>
          </div>
          <div className='Right_R_Main_Nav'>

          <ul className='Rr_Tt_Ul'>
            <li>
              <Link to="/about" aria-label="Go to About" onClick={handleCardClick}>About Us</Link>
            </li>
            <li className='DropDown_BtnNN'>
            <Link to="/services" aria-label="Go to About" onClick={handleCardClick}><p>Our Services 
                <img src={DropIcon} alt='Drop icon'></img>
                </p>
              </Link>

              <div className='ahahhaa_Dropa'>
              <Link to="/consultancy" className='OODrop_Down_Card' onClick={handleCardClick}>
                Consultancy
              </Link>

              <Link to="/engineering" className='OODrop_Down_Card' onClick={handleCardClick}>
                Engineering
              </Link>

              <Link to="/project-management" className='OODrop_Down_Card' onClick={handleCardClick}>
                Project Management
              </Link>

              <Link to="/supply-chain-management" className='OODrop_Down_Card' onClick={handleCardClick}>
                Supply Chain Management
              </Link>

              <Link to="/learning-and-development" className='OODrop_Down_Card' onClick={handleCardClick}>
                Learning and Development
              </Link>
              </div>


            </li>
            <li>
              <Link to="/industries" aria-label="Go to Industries" onClick={handleCardClick}>Industries</Link>
            </li>
            <li>
              <Link to="/careers" aria-label="Go to Careers" onClick={handleCardClick}>Careers</Link>
            </li>
            {/* <li>
              <Link to="/news" aria-label="Go to News" onClick={handleCardClick}>News and Events</Link>
            </li> */}
            <li>
              <Link to="/contact-us" aria-label="Go to Contact Us" onClick={handleCardClick}>Contact Us</Link>
            </li>
          </ul>

            <div className='Pp_All_Daf'>
    
              <div className='Nav_Social_icons'>
                <p>Connect with us</p>
                <span><img src={AngleIcon} alt='Angle Icon' /></span>
                <div className='social_Icons_main'>
                <a href="https://www.linkedin.com/company/proliance-limited/about/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M7.734 27V9.48H2.285V27h5.45zM4.98 7.137c1.758 0 3.165-1.465 3.165-3.223C8.145 2.214 6.738.81 4.98.81a3.126 3.126 0 00-3.105 3.105c0 1.758 1.406 3.223 3.105 3.223zM28.066 27h.059v-9.61c0-4.687-1.055-8.32-6.563-8.32-2.636 0-4.394 1.465-5.156 2.813h-.058V9.48h-5.215V27h5.449v-8.672c0-2.285.41-4.453 3.223-4.453 2.812 0 2.87 2.578 2.87 4.629V27h5.391z" fill="currentColor"></path></svg>
            </a>
            <a href="https://twitter.com/prolianceltd" target='_blank'>
              <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M4.054 4l8.493 12.136L4 26h1.924l7.483-8.637L19.455 26H26l-8.972-12.817L24.984 4H23.06l-6.891 7.953L10.599 4H4.055zm2.829 1.514H9.89l13.28 18.971h-3.007L6.883 5.515z" fill="currentColor"></path></svg>
            </a>
            <a href="https://www.facebook.com/prolianceltd/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M21.973 17.625l.82-5.39h-5.215V8.718c0-1.524.703-2.93 3.047-2.93h2.402V1.16S20.86.75 18.81.75c-4.278 0-7.09 2.637-7.09 7.324v4.16H6.914v5.391h4.805V30.75h5.86V17.625h4.394z" fill="currentColor"></path></svg>
            </a>
                </div>
              </div>
              <button className='Chat_Btn' onClick={toggleLetTalkVisibility}>
                <img src={ChatIcon} alt='Chat Icon' />
                <span>Let’s talk</span>
              </button>
              <div className='Nav_Toggler' onClick={handleNavToggle}>
                <span></span><span></span><span></span>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='Nav_Body' onClick={handleNavBodyClick}></div>
      <div className='Mobile_nav'>
        
        <div className='Nav_Social_icons'>
          <p>Connect with us</p>
          <span><img src={AngleIcon} alt='Angle Icon' /></span>
          <div className='social_Icons_main'>
          <a href="https://www.linkedin.com/company/proliance-limited/about/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M7.734 27V9.48H2.285V27h5.45zM4.98 7.137c1.758 0 3.165-1.465 3.165-3.223C8.145 2.214 6.738.81 4.98.81a3.126 3.126 0 00-3.105 3.105c0 1.758 1.406 3.223 3.105 3.223zM28.066 27h.059v-9.61c0-4.687-1.055-8.32-6.563-8.32-2.636 0-4.394 1.465-5.156 2.813h-.058V9.48h-5.215V27h5.449v-8.672c0-2.285.41-4.453 3.223-4.453 2.812 0 2.87 2.578 2.87 4.629V27h5.391z" fill="currentColor"></path></svg>
            </a>
            <a href="https://twitter.com/prolianceltd" target='_blank'>
              <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M4.054 4l8.493 12.136L4 26h1.924l7.483-8.637L19.455 26H26l-8.972-12.817L24.984 4H23.06l-6.891 7.953L10.599 4H4.055zm2.829 1.514H9.89l13.28 18.971h-3.007L6.883 5.515z" fill="currentColor"></path></svg>
            </a>
            <a href="https://www.facebook.com/prolianceltd/" target='_blank'>
              <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="SocialMediaLinks_m-social-media-links__icon__aLbPt"><path d="M21.973 17.625l.82-5.39h-5.215V8.718c0-1.524.703-2.93 3.047-2.93h2.402V1.16S20.86.75 18.81.75c-4.278 0-7.09 2.637-7.09 7.324v4.16H6.914v5.391h4.805V30.75h5.86V17.625h4.394z" fill="currentColor"></path></svg>
            </a>
          </div>
        </div>
        <ul className='Mmn_UlL1'>
          <li><Link to="/contact-us" aria-label="Go to Contact Us" className='contact-btn' onClick={handleLinkClick}>Contact Us</Link></li>
          <li><Link to="/about" aria-label="Go to About" onClick={handleLinkClick}>About Us</Link></li>
          <li><Link to="/industries" aria-label="Go to Industries" onClick={handleLinkClick}>Industries</Link></li>
          <li><Link to="/careers" aria-label="Go to Careers" onClick={handleLinkClick}>Careers</Link></li>
          {/* <li><Link to="/news" aria-label="Go to News" onClick={handleLinkClick}>News and Events</Link></li> */}
          <li><Link to="/services" onClick={handleLinkClick}><p>Our Services</p></Link></li>
          <li><Link to="/consultancy" onClick={handleLinkClick}>Consultancy</Link></li>
          <li><Link to="/engineering" onClick={handleLinkClick}>Engineering</Link></li>
          <li><Link to="/project-management" onClick={handleLinkClick}>Project Management</Link></li>
          <li><Link to="/supply-chain-management" onClick={handleLinkClick}>Supply Chain Management</Link></li>
          <li><Link to="/learning-and-development" onClick={handleLinkClick}>Learning and Development</Link></li>
        </ul>
      </div>
      <div className={`Let_talk_sec ${isLetTalkVisible ? 'Show_Let_talk_sec' : ''}`} ref={sectionRef}>
        <div className='Let_talk_Box'>
          <div className='Let_talk_Banner'>
            <button className='Close_Let_talk_sec' onClick={handleCloseButtonClick}></button>
          </div>
          <div className='Let_talk_Dlt'>
          <h3>Let's Talk</h3>
          
          <p className='p'>You can message us via  whatsapp, or you can call us for quick response.</p>

          <div className='Ll_btns'>
          <a
            href="https://wa.me/09114597013"
            className="whatsapp-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={WhatSappIcon} alt="Whatsapp icon" />
            <span>Whatsapp</span>
          </a>

          <a href="tel:+2348079701019">
            <img src={CallIcon} alt="Call icon" />
            <span>Call</span>
          </a>
          </div>
        </div>
        </div>
      </div>











    </nav>
  );
};

export default Navbar;
