import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNavbar from './components/TopNavbar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import ScrollToTop from './components/ScrollToTop';
import './App.css';
import 'aos/dist/aos.css';

import AOS from 'aos';

// Import Cookie Components
import CookieConsentBanner from './components/CookieConsentBanner';
import CookieSettingsModal from './components/CookieSettingsModal';

// Lazy load components
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Industries = lazy(() => import('./pages/Industries'));
const Careers = lazy(() => import('./pages/Careers'));
const News = lazy(() => import('./pages/News'));
const Contact = lazy(() => import('./pages/Contact'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Services1 = lazy(() => import('./pages/Services1'));
const Services2 = lazy(() => import('./pages/Services2'));
const Services3 = lazy(() => import('./pages/Services3'));
const Services4 = lazy(() => import('./pages/Services4'));
const Services5 = lazy(() => import('./pages/Services5'));
const MainServices = lazy(() => import('./pages/MainServices'));

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000, once: false });
  }, []);

  // State for managing cookies
  const [isCookieModalOpen, setCookieModalOpen] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(() => localStorage.getItem('cookiesAccepted') === 'true');

  // Accept Cookies Handler
  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  // Reject Cookies Handler
  const handleRejectCookies = () => {
    setCookiesAccepted(false);
    localStorage.setItem('cookiesAccepted', 'false');
  };

  // Manage Cookies (Open Settings Modal)
  const handleManageCookies = () => setCookieModalOpen(true);

  // Close Cookie Modal
  const handleCloseCookieModal = () => setCookieModalOpen(false);

  return (
    <Router>
      <ScrollToTop />
      <TopNavbar />
      <Navbar />

      <Suspense fallback={<div className="Page_Loader">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/industries" element={<Industries />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/consultancy" element={<Services1 />} />
          <Route path="/engineering" element={<Services2 />} />
          <Route path="/project-management" element={<Services3 />} />
          <Route path="/supply-chain-management" element={<Services4 />} />
          <Route path="/learning-and-development" element={<Services5 />} />
          <Route path="/services" element={<MainServices />} />
        </Routes>
      </Suspense>

      <Footer />

      {!cookiesAccepted && (
        <CookieConsentBanner
          onAccept={handleAcceptCookies}
          onReject={handleRejectCookies}
          onManage={handleManageCookies}
        />
      )}

      <CookieSettingsModal
        isOpen={isCookieModalOpen}
        onClose={handleCloseCookieModal}
        onAccept={handleAcceptCookies}
        onReject={handleRejectCookies}
      />
    </Router>
  );
}

export default App;
